import React, { useContext, useEffect, useState } from "react"
import css from "./Pokemon.module.css"

function Pokemon() {
  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
  }, [])

  return (
    <div>
      <a
        className={css.seeLiveProject7}
        target="_blank"
        rel="noopener noreferrer"
        href="https://zen-mirzakhani-11537a.netlify.app/"
      >
        See Live
      </a>
      <br></br>
      <a
        className={css.sourceCodeProject7}
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/impactyogi/gatsbyMealThings"
      >
        Source Code
      </a>
    </div>
  )
}

export default Pokemon
