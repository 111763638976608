import React, { useContext, useEffect, useState } from "react"
import css from "./MecanoCNC.module.css"

function Mecano() {
  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
  }, [])

  return (
    <div>
      <a
        className={css.seeLiveProject12}
        target="_blank"
        rel="noopener noreferrer"
        href="../../images/workinprogress.jpg"
      >
        See Live
      </a>
      <br></br>
      <a
        className={css.sourceCodeProject12}
        target="_blank"
        rel="noopener noreferrer"
        href="../../images/workinprogress.jpg"
      >
        Source Code
      </a>
    </div>
  )
}

export default Mecano
