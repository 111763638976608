import React, { useContext, useEffect, useState } from "react";
import css from "./CRM1.module.css";

function CRM1() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <div>
      <a
        className={css.seeLiveProject9}
        target="_blank"
        rel="noopener noreferrer"
        href="https://modest-heisenberg-43ee92.netlify.com"
      >
        See Live
      </a>
      <br></br>
      <a
        className={css.sourceCodeProject9}
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/impactyogi/front-end-week-8-project-team-404"
      >
        Source Code
      </a>
    </div>
  );
}

export default CRM1;
